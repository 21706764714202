import * as React from 'react'
import 'page/CampaignDetailsPage.scss'
import { useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import { NavLink } from 'util/routing'
import * as ROUTES from 'const/routes'

import { IContactFilterResponseData } from 'api/response'
import { ContactFilterBuilder } from 'components/ContactFilterBuilder/ContactFilterBuilder'
import { noOp } from 'util/noOp'
import { getHistoricalContactFilter, getCampaignEngagementData } from 'api'
import { isRight } from 'fp-ts/lib/Either'
import Loader from 'components/Loader/Loader'
import {
  WebData,
  Loading,
  Success,
  Failure,
  isLoading,
  isSuccess,
} from 'store/webdata'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionGuard from 'util/permissions/PermissionGuard'

interface IEngagementStatProps {
  title: string
  count: number
}

const EngagementStat = ({ title, count }: IEngagementStatProps) => {
  return (
    <div className="d-flex flex-column justify-content-start pr-5 mainstay-dark-blue-80">
      <div className="mainstay-body-paragraph">{title}</div>
      <div>{count}</div>
    </div>
  )
}

const AudienceRulesSection = ({
  contactFilterId,
  historicalContactFilterObj,
}: {
  contactFilterId: number
  historicalContactFilterObj: IContactFilterResponseData
}) => {
  return (
    <>
      <div className="mainstay-header-h5 mainstay-dark-blue-80">
        Audience rule description
      </div>
      <div className="mainstay-body-paragraph mainstay-dark-blue-80 mb-4">
        This audience contains contacts who met the following conditions when
        the campaign was sent.
      </div>
      <ContactFilterBuilder
        filterId={contactFilterId}
        allFilters={undefined}
        disableRecipientsPreview={undefined}
        onCancel={noOp}
        onSaveAndContinue={noOp}
        readOnly={true}
        historicalContactFilterObj={historicalContactFilterObj}
      />
    </>
  )
}

interface ICampaignAudienceSectionProps {
  contactFilterName: string
  contactFilterId: number
  scheduledAt: Date | null
  campaignId: string
  isRecurring: boolean
  isDataTriggered?: boolean
}

interface ICampaignRecipientsEngagementProps {
  pausesCount: number
  optOutsCount: number
  campaignsSentCount: number
  totalContacts: number
}

export const CampaignAudienceSection = ({
  contactFilterName,
  contactFilterId,
  scheduledAt,
  isDataTriggered,
  campaignId,
  isRecurring,
}: ICampaignAudienceSectionProps) => {
  const dateFormat = useSelector(getInstitutionDateFormat)

  const [
    historicalContactFilterObj,
    setHistoricalContactFilterObj,
  ] = React.useState<{
    contact_filter: IContactFilterResponseData | null
    deleted: boolean
  }>()
  const [engagementWebData, setEngagementWebdata] = React.useState<
    WebData<ICampaignRecipientsEngagementProps>
  >(Loading())

  React.useEffect(() => {
    if (!isDataTriggered) {
      getCampaignEngagementData(campaignId, isRecurring).then(res => {
        if (isRight(res)) {
          const {
            total_contacts,
            count_campaigns_sent,
            pauses,
            opt_outs,
          } = res.right.engagement_data
          setEngagementWebdata(
            Success({
              pausesCount: pauses,
              optOutsCount: opt_outs,
              campaignsSentCount: count_campaigns_sent ?? 1,
              totalContacts: total_contacts,
            })
          )
        } else {
          setEngagementWebdata(Failure())
        }
      })
    }
    getHistoricalContactFilter(campaignId, isRecurring, isDataTriggered).then(
      res => {
        if (isRight(res)) {
          setHistoricalContactFilterObj({
            contact_filter: res.right.contact_filter
              ? {
                  ...res.right.contact_filter,
                  id: res.right.contact_filter.id,
                  size: null,
                  last_calculated: null,
                }
              : null,
            deleted: res.right.deleted,
          })
        }
      }
    )
  }, [campaignId, isRecurring, isDataTriggered])

  return (
    <>
      <div className="d-flex align-items-center mt-4">
        <div className="mainstay-header-h4 mr-2 text-mainstay-dark-blue">
          {contactFilterName}
        </div>
        {!historicalContactFilterObj?.deleted && (
          <NavLink
            eventLocation="campaigns"
            eventAction="click"
            eventObject="audience view in audience manager"
            target="_blank"
            className="text-decoration-none expand-link mainstay-body-caption mt-1"
            to={`${ROUTES.CONTACTS.AUDIENCES}${contactFilterId}`}>
            View in Audience Manager
          </NavLink>
        )}
      </div>

      {!isDataTriggered && (
        <>
          {isLoading(engagementWebData) ? (
            <Loader />
          ) : isSuccess(engagementWebData) ? (
            <div className="d-flex align-items-center mt-4">
              <EngagementStat
                title="Total Contacts"
                count={engagementWebData.data.totalContacts}
              />
              <EngagementStat
                title="Opt Outs"
                count={engagementWebData.data.optOutsCount}
              />
              <EngagementStat
                title="Pauses"
                count={engagementWebData.data.pausesCount}
              />
              <EngagementStat
                title="Campaigns"
                count={engagementWebData.data.campaignsSentCount}
              />
            </div>
          ) : (
            <div> Unable to fetch engagement data </div>
          )}
          {scheduledAt ? (
            <div className="mainstay-body-caption mainstay-dark-blue-80 mt-2">
              As of {strftime(dateFormat, scheduledAt)}
            </div>
          ) : null}
        </>
      )}

      <hr />
      <PermissionGuard permission={PERMISSIONS.AUDIENCE.VIEW} renderNothing>
        {historicalContactFilterObj?.contact_filter && (
          <AudienceRulesSection
            contactFilterId={contactFilterId}
            historicalContactFilterObj={
              historicalContactFilterObj.contact_filter
            }
          />
        )}
      </PermissionGuard>
    </>
  )
}
