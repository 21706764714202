import { useState, useEffect } from 'react'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { SelectInputValue } from 'components/TimezoneSelect/TimezoneSelect'
import moment, { Moment } from 'moment-timezone'
import { isNil } from 'lodash'
import { Link } from 'util/routing'
import { ICampaignSendWindow } from 'api/response'
import classnames from 'classnames'

export function CampaignHoursForm({
  campaignSendWindow,
  onStartTimeChange,
  onEndTimeChange,
  readonlyHours,
}: {
  campaignSendWindow: ICampaignSendWindow
  onStartTimeChange?: (
    hour: number | undefined,
    minute: number | undefined,
    type: string
  ) => void
  onEndTimeChange?: (
    hour: number | undefined,
    minute: number | undefined,
    type: string
  ) => void
  readonlyHours?: boolean
}) {
  const [from, setFrom] = useState<moment.Moment | undefined>()
  const [to, setTo] = useState<moment.Moment | undefined>()
  const [timezone, setTimezone] = useState<SelectInputValue | null>()

  useEffect(() => {
    const {
      hoursStart,
      minutesStart,
      hoursEnd,
      minutesEnd,
      timezone,
    } = campaignSendWindow

    if (
      isNil(hoursStart) ||
      isNil(minutesStart) ||
      isNil(hoursEnd) ||
      isNil(minutesEnd)
    ) {
      return
    }

    if (
      isNil(hoursStart) &&
      isNil(minutesStart) &&
      isNil(hoursEnd) &&
      isNil(minutesEnd)
    ) {
      setFrom(undefined)
      setTo(undefined)
    }
    const defaultTimezone = timezone || moment.tz.guess()
    setTimezone({ label: defaultTimezone, value: defaultTimezone })

    const fromDate = moment()
      .utc()
      .set({ hour: hoursStart, minute: minutesStart })
    const toDate = moment()
      .utc()
      .set({ hour: hoursEnd, minute: minutesEnd })
    setFrom(fromDate)
    setTo(toDate)
  }, [campaignSendWindow])

  function handleDateChange(date: moment.Moment, type: string): void {
    if (!date && type === 'from' && onStartTimeChange) {
      setFrom(undefined)
      return onStartTimeChange(undefined, undefined, 'from')
    }

    if (!date && type === 'to' && onEndTimeChange) {
      setTo(undefined)
      return onEndTimeChange(undefined, undefined, 'to')
    }

    const parsedDate = getParsedDate(date)

    if (!onStartTimeChange || !onEndTimeChange) {
      return
    }

    if (type === 'from') {
      setFrom(date)
      onStartTimeChange(parsedDate.hour(), parsedDate.minute(), 'from')
    } else {
      setTo(date)
      onEndTimeChange(parsedDate.hour(), parsedDate.minute(), 'to')
    }
  }

  function getParsedDate(date: moment.Moment): moment.Moment {
    return moment().set({
      hour: date.hour(),
      minute: date.minute(),
    })
  }

  return (
    <div className="d-block">
      <div className="mr-2">
        <p className="caption text-muted mb-1">
          Only send campaigns between these hours:
        </p>
        <div
          className={classnames('d-flex align-items-center', {
            'max-width-250 flex-wrap': !readonlyHours,
          })}>
          <TimePicker
            showSecond={false}
            disabled={readonlyHours}
            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
            value={(from as unknown) as Moment}
            onChange={(date: moment.Moment) => handleDateChange(date, 'from')}
            format="h:mm a"
            placeholder="select"
            className={classnames('form-control', {
              'max-width-100px': !readonlyHours,
              'max-width-200': readonlyHours,
              disabled: readonlyHours,
            })}
            use12Hours
          />
          <span className="pl-2 pr-2 pb-2">-</span>
          <TimePicker
            showSecond={false}
            disabled={readonlyHours}
            /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
            value={(to as unknown) as Moment}
            onChange={(date: moment.Moment) => handleDateChange(date, 'to')}
            format="h:mm a"
            placeholder="select"
            className={classnames('form-control', {
              'max-width-100px': !readonlyHours,
              'max-width-200': readonlyHours,
              disabled: readonlyHours,
            })}
            use12Hours
          />
          {readonlyHours && (
            <div className="mx-3">
              <div className="d-flex flex-row align-items-center">
                <div className="d-block">
                  <div>{timezone?.value}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!readonlyHours && (
        <div className="mb-2 my-2">
          <p className="caption text-muted mb-1">Time Zone</p>
          <div className="d-flex flex-row align-items-center">
            <div className="d-block">
              <div>{timezone?.value}</div>
              <small>
                <Link to="/settings" hash="timezone">
                  Change your time zone
                </Link>
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
