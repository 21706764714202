import { useMemo } from 'react'
import 'page/users-v2/NewUserForm.scss'
import AutoSuggest from 'components/AutoSuggest/AutoSuggest'
import { useSelector } from 'util/hooks'
import { getAvailableUserGroups } from 'store/triage/profile/selectors'
import { difference, isArray } from 'lodash'

export const NATIONAL_USER = 'All User Groups' // User with access to all regions (user groups)
interface IRegionOption {
  label: string
  value: string
}

interface IRegionSelectProps {
  selectedUserGroups: IRegionOption['value'][]
  onChange: (value: string[]) => void
  disabled?: boolean
  eventObject: string
  className?: string
}

export function RegionSelect({
  selectedUserGroups,
  eventObject,
  className,
  disabled,
  onChange,
}: IRegionSelectProps) {
  const availableRegions = useSelector(getAvailableUserGroups)

  const isUserGroupValid = useMemo(() => {
    if (selectedUserGroups.includes(NATIONAL_USER)) {
      if (selectedUserGroups.length > 1) {
        return false
      }
      return true
    }

    return selectedUserGroups.every(item => availableRegions.includes(item))
  }, [selectedUserGroups, availableRegions])

  const mapAvailableRegionsToOptions = (): IRegionOption[] => {
    return [
      { label: NATIONAL_USER, value: NATIONAL_USER },
      ...availableRegions.map(region => ({ label: region, value: region })),
    ]
  }

  const setUserGroups = (
    e: React.ChangeEvent<{ value: string[] | string }>
  ) => {
    const value = e.target.value
    if (!isArray(value)) {
      return
    }

    const newAdditions = difference(selectedUserGroups, value)

    // If the user clicks the "clear" button, it will pass an empty array.
    // We should just set "National" in this case
    if (value.length === 0) {
      onChange([NATIONAL_USER])
      return
    }

    // If "National" is selected, it should be the only user group selected.
    // If a user starts off with "National", and then adds a non-national user group, remove "National".
    if (
      selectedUserGroups.includes(NATIONAL_USER) &&
      !newAdditions.includes(NATIONAL_USER)
    ) {
      onChange(value.filter((x: string) => x !== NATIONAL_USER))
      return
    }

    // If user has non-national values, and selects "National", clear out non-national values
    if (
      !selectedUserGroups.includes(NATIONAL_USER) &&
      value.includes(NATIONAL_USER)
    ) {
      onChange([NATIONAL_USER])
      return
    }

    // If "National" isn't in the picture at all, just set the selected user groups
    onChange(value)
  }

  return (
    <AutoSuggest<IRegionOption>
      placeholder="Select one or more User Groups"
      id="userGroups"
      name="userGroups"
      isValid={isUserGroupValid}
      value={selectedUserGroups}
      eventObject={eventObject}
      className={className}
      disabled={disabled}
      multi={true}
      onChange={setUserGroups}
      options={mapAvailableRegionsToOptions()}
    />
  )
}
