import { IntroCampaignListItemType, listWebchatIntroCampaigns } from 'api'
import { calculateResponsePercentage } from 'components/CampaignCard/CampaignCard'
import {
  IAsyncRows,
  IAsyncTableOptions,
  SortTableAsync,
} from 'components/TableAsyncSort/TableAsyncSort'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { isRight } from 'fp-ts/lib/Either'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableRow } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import React, { useCallback, useEffect } from 'react'
import { generatePath } from 'react-router'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import { useSelector } from 'util/hooks'
import { NavLink } from 'util/routing'
const DEFAULT_PAGE_SIZE = 30

const WebchatIntroRow = ({ rowRes }: { rowRes: IntroCampaignListItemType }) => {
  const dateFormat = useSelector(getInstitutionDateFormat)

  const responseRate =
    calculateResponsePercentage(
      rowRes.countEligibleUsersProcessed,
      rowRes.distinctResponsesCount
    ) ?? 0

  const getCampaignLastSent = React.useCallback(() => {
    if (!rowRes.lastSentDate) {
      return '-'
    }
    const sentDate = new Date(rowRes.lastSentDate)
    return (
      <>
        <h5 className="fw-400 mb-0">{strftime(dateFormat, sentDate)}</h5>
        <h5 className="fw-400">{strftime('%l:%M %P', sentDate)}</h5>
      </>
    )
  }, [dateFormat, rowRes.lastSentDate])

  return (
    <NavLink
      to={generatePath(CAMPAIGN_HISTORY.WEBCHAT_INTRO_DETAIL, {
        id: rowRes.webBotId,
        dialogId: rowRes.currentDialogId,
      })}
      className="w-100 campaign-row pointer hover-text-decoration-none">
      <MainstayFlexTableRow className="text-mainstay-dark-blue-80 hover-bg-mainstay-dark-blue-10">
        <MainstayFlexTableCol xs={5}>
          <div className="d-flex align-items-center">
            <h5 className="fw-600 mb-0 mr-2">{rowRes.webBotName}</h5>
          </div>
          <h5 className="fw-400" />
          <div className="fw-400">
            When a contact initiates a web chat, they will enter the web
            introduction script "{rowRes.dialogName}"{' '}
          </div>
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          <h5 className="fw-600 mb-0">
            {rowRes.isRespondable ? 'Interactive' : 'Nudge'}
          </h5>
          {rowRes.isRespondable && (
            <h5 className="fw-400">{`${responseRate}%`}</h5>
          )}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol xs={2}>
          {rowRes.countEligibleUsersProcessed}
        </MainstayFlexTableCol>
        <MainstayFlexTableCol className="fw-400" xs={2}>
          {getCampaignLastSent()}
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </NavLink>
  )
}

export const WebchatIntroCampaignsTable = ({
  debouncedQuery,
}: {
  debouncedQuery?: string
}) => {
  const loadWebchatIntroCampaigns = useCallback(
    async (
      { page = 1, sortBy, order }: IAsyncTableOptions,
      pageSize: number = DEFAULT_PAGE_SIZE
    ): Promise<IAsyncRows> => {
      const res = await listWebchatIntroCampaigns({
        sortBy,
        order,
        page,
        pageSize,
        query: debouncedQuery,
      })
      if (isRight(res)) {
        return {
          total: res.right.count || 0,
          rows: res.right.results.map(rowRes => [
            <WebchatIntroRow key={rowRes.webBotName} rowRes={rowRes} />,
          ]),
        }
      }
      return { total: 0, rows: [] }
    },
    [debouncedQuery]
  )

  useEffect(() => {
    loadWebchatIntroCampaigns(
      { sortBy: 'date', order: 'desc' },
      DEFAULT_PAGE_SIZE
    )
  }, [debouncedQuery, loadWebchatIntroCampaigns])

  return (
    <SortTableAsync
      itemsName="webchat intro campaigns"
      loadRows={loadWebchatIntroCampaigns}
      pageSize={DEFAULT_PAGE_SIZE}
      rowEventObject="individual webchat intro campaign"
      pagerEventObject="of webchat intro campaigns"
      initialTableOptions={{
        sortBy: 'date',
        order: 'desc',
      }}
      columns={[
        {
          title: 'Name',
          xs: 5,
          sortByField: 'name',
        },
        {
          title: 'Type',
          xs: 2,
          alignment: 'start',
          sortByField: 'type',
        },
        {
          title: 'Sent',
          xs: 2,
          alignment: 'start',
          sortByField: 'sent',
        },
        {
          title: 'Last Sent',
          xs: 2,
          alignment: 'start',
          sortByField: 'date',
        },
      ]}
    />
  )
}
