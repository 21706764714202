import { RootState as IState } from 'store/store'
import { ICampaignHistoryPageRouteProps } from 'page/campaign/CampaignHistory'
import { CAMPAIGNS_PER_PAGE } from 'store/campaign-history/thunks'
import { getQueryFilters } from 'util/queryFilters'

export const getIsCampaignHistoryLoading = (state: IState) =>
  state.campaignHistory.loading

export const getSortForCampaigns = (state: IState) =>
  state.campaignHistory.sortBy

export const getCampaignList = (state: IState) =>
  state.campaignHistory.campaigns

export const getCampaignTriggers = (state: IState) =>
  state.campaignHistory.triggers

export const getCampaignHistoryPagerCount = (state: IState) => {
  const counts = state.campaignHistory.campaignCountsByType
  const sidebarFilter = state.campaignHistory.sidebarFilter
  const indices = {
    all: 'totalCount',
    sent: 'sentCount',
    sending: 'sendingCount',
    upcoming: 'upcomingCount',
    recurring: 'recurringCount',
    is_webchat_intro: 'webchatIntroCount',
    is_intro: 'totalIntroCount',
    is_other_intro: 'otherIntroCount',
    is_datatriggered: 'dataTriggeredCount',
    is_hellopage: 'helloPagesCount',
  }
  const filter = indices[sidebarFilter]
  return counts ? Math.ceil((counts[filter] ?? 0) / CAMPAIGNS_PER_PAGE) : null
}

export const getCampaignsHistoryCountsByType = (state: IState) =>
  state.campaignHistory.campaignCountsByType

export const getCurrentCampaignHistoryPage = (
  ownProps: ICampaignHistoryPageRouteProps
) => {
  const { page } = getQueryFilters(ownProps.location)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return page ? parseInt(page as string, 10) : 1
}

export const getCampaignSidebarFilter = (state: IState) =>
  state.campaignHistory.sidebarFilter
