import { KnowledgeBaseReviewItemShapeType } from 'api'
import { ActionCheckbox } from 'components/ActionCheckbox/ActionCheckbox'
import { Button } from 'components/Button/Button'
import { KnowledgeDrawerHeader } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelClone'
import { KnowledgeSeedEditPanelContent } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelContent'
import { Pager } from 'components/LinkPager/LinkPager'
import Loader from 'components/Loader/Loader'
import { MainstayDrawer } from 'components/MainstayDrawer/MainstayDrawer'
import { MainstayDrawerFooter } from 'components/MainstayDrawerFooter/MainstayDrawerFooter'
import { MainstayFlexTableHeaderCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableHeader } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import {
  KnowledgeReviewReason,
  knowledgeReviewRouterParamToEnum,
} from 'page/conversations-v2/ConversationsDetail'
import {
  resetCheckboxList,
  selectAllFromCheckboxList,
} from 'page/knowledge-base/KnowledgeReview/actions'
import {
  getKnowledgeBaseReviewItemsParams,
  KB_REVIEW_ITEMS_PER_PAGE,
} from 'page/knowledge-base/KnowledgeReview/KnowledgeReview'
import { KnowledgeReviewCheckboxHeader } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewCheckboxHeader/KnowledgeReviewCheckboxHeader'
import { KnowledgeReviewSelectionNotification } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewSelectionNotification/KnowledgeReviewSelectionNotification'
import 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTable.scss'
import KnowledgeReviewTableRow, {
  ConversationMessageLink,
} from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTableRow/KnowledgeReviewTableRow'
import { KnowledgeBaseReviewItemsStatus } from 'page/knowledge-base/KnowledgeReview/reducer'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import React, { useRef } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'util/hooks'
import { appendQueryFilter } from 'util/queryFilters'

const KnowledgeReviewTable = ({
  fetchData,
  fetchCounts,
}: {
  readonly fetchData: () => void
  readonly fetchCounts: () => void
}) => {
  const dispatch = useDispatch()
  const {
    data: { items, status, totalCount },
    checkboxList: { selectedIds, isLoading, all, deselectedIds },
  } = useSelector(getKnowledgeBaseReviewItems)
  const routerParams = useParams<{
    readonly reason: string
  }>()
  const [checkedAllVisible, setCheckedAllVisible] = React.useState<boolean>(
    false
  )
  const [createdUnderstandingId, setCreatedUnderstandingId] = React.useState<
    string | undefined
  >(undefined)
  const [selectedReviewItem, setSelectedReviewItem] = React.useState<
    KnowledgeBaseReviewItemShapeType | undefined
  >()
  const { page } = getKnowledgeBaseReviewItemsParams(routerParams.reason)
  const reason = knowledgeReviewRouterParamToEnum(routerParams.reason)

  const isAllMessagesTab = !routerParams.reason

  // Creating a bunch of Refs for use in KB Panel Answer Animations
  const editPanelRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setCheckedAllVisible(!!selectedIds.length)
  }, [selectedIds.length])

  React.useEffect(() => {
    // on router param change, we want to reset the checkbox values
    dispatch(resetCheckboxList())
  }, [dispatch, routerParams.reason])

  const renderTableHeaderRow = React.useCallback(() => {
    switch (reason) {
      case KnowledgeReviewReason.BotCouldNotAnswer:
      case KnowledgeReviewReason.AnswerIncorrect:
        return (
          <>
            <MainstayFlexTableHeaderCol xs={4}>
              Bot Response
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={3}>
              Notes
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={1} />
          </>
        )

      default:
        return (
          <>
            <MainstayFlexTableHeaderCol xs={3}>
              Matched Understanding
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={4}>
              Bot Response
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={1} />
          </>
        )
    }
  }, [reason])

  if (status === KnowledgeBaseReviewItemsStatus.loading || isLoading) {
    return (
      <div className="d-flex justify-content-center flex-grow-1 align-items-center">
        <Loader />
      </div>
    )
  }

  if (status === KnowledgeBaseReviewItemsStatus.error) {
    return (
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div>Something went wrong, please try again later.</div>
      </div>
    )
  }
  if (
    status === KnowledgeBaseReviewItemsStatus.ok &&
    (totalCount === 0 || !items.length)
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div>No messages in this inbox</div>
      </div>
    )
  }

  const tableClass = `knowledge-review-table ${routerParams.reason || ''}`
  const indeterminate = all
    ? deselectedIds.length > 0
    : selectedIds.length > 0 && selectedIds.length < totalCount

  const handleCreateNewUnderstanding = (createdUnderstandingId: string) => {
    setCreatedUnderstandingId(createdUnderstandingId)
  }

  const handleSelectReviewItem = (item: KnowledgeBaseReviewItemShapeType) => {
    setSelectedReviewItem(item)
  }

  // From the a just-created Understanding in Edit mode, reset local state
  // and refetch data on close.
  // (click out of drawer, click X in header, click "Finished with maintenance" button in footer).
  const handleCloseReviewDrawer = () => {
    setSelectedReviewItem(undefined)
    setCreatedUnderstandingId(undefined)
    fetchData()
    fetchCounts()
  }

  return (
    <>
      <MainstayFlexTable className={tableClass}>
        <MainstayFlexTableHeader className="d-flex align-items-center">
          <MainstayFlexTableHeaderCol>
            <div className="d-flex align-items-center">
              <ActionCheckbox
                id="all"
                show
                indeterminate={indeterminate}
                isChecked={checkedAllVisible}
                selectedIds={selectedIds}
                deselectedIds={deselectedIds}
                itemIds={items.map(i => i.message.id)}
                onAddToCheckboxList={() => {
                  dispatch(
                    selectAllFromCheckboxList({
                      all: false,
                      selectedIds: items.map(i => i.message.id),
                    })
                  )
                }}
                onRemoveFromCheckboxList={() => {
                  dispatch(
                    selectAllFromCheckboxList({
                      all: false,
                      selectedIds: [],
                    })
                  )
                }}
              />
              {selectedIds.length === 0 && (
                <span className="ml-1">Incoming Message</span>
              )}
              {selectedIds.length > 0 && (
                <KnowledgeReviewCheckboxHeader
                  isAllMessageTab={isAllMessagesTab}
                  fetchCounts={fetchCounts}
                  count={totalCount}
                />
              )}
            </div>
          </MainstayFlexTableHeaderCol>
          {selectedIds.length === 0 && renderTableHeaderRow()}
        </MainstayFlexTableHeader>
        <>
          {selectedIds.length > 0 && <KnowledgeReviewSelectionNotification />}
          {items.map(item => (
            <KnowledgeReviewTableRow
              key={item.message.id}
              item={item}
              fetchData={fetchData}
              fetchCounts={fetchCounts}
              isAllMessagesTab={isAllMessagesTab}
              reason={reason}
              onCreateNewUnderstanding={handleCreateNewUnderstanding}
              onClick={handleSelectReviewItem}
            />
          ))}
          {/*
          NOTE(neckenth - 2024-01-17):
          Render the KB Panel in Edit mode here if the user has created a new
          understanding as a response to a "Missed" or "Incorrect" message in
          Knowledge Maintenance.

          Other KB Panel modes (Creation, Search for Existing) are rendered by
          the child <KnowledgeReviewTableRow /> component via the <AllMessagesDrawer />.
          
          We cannot do the same with the Edit mode immediately post-creation
          because after clicking "Next" (submit) on the Creation panel, the
          Knowledge Maintenance message is marked as "reviewed", which removes
          their row from the table, causing a re-render and a loss of the created
          Understanding ID, which is needed for rendering the Understanding
          in Edit mode.

          Hoisting this into the parent allows us to conditionally show the Edit
          panel for a just-created Understanding in response to a Knowledge
          Maintenance message.
          */}
          {!!createdUnderstandingId && !!selectedReviewItem ? (
            <MainstayDrawer
              panelClassName="mb-3rem"
              containerClassName="panel h-100 pb-4"
              open={!!createdUnderstandingId}
              onClose={handleCloseReviewDrawer}
              showHr
              header={
                <KnowledgeDrawerHeader
                  title={`“${selectedReviewItem.message.body}”`}
                  titleClassName="ml-3 matched-understanding-title truncate-title"
                  onClose={handleCloseReviewDrawer}
                  knowledgeMatchDrawer={true}
                  conversationMessageLink={
                    <ConversationMessageLink reviewItem={selectedReviewItem} />
                  }
                />
              }>
              <KnowledgeSeedEditPanelContent
                understandingId={createdUnderstandingId}
                isNewlyCreated={true}
                onClose={handleCloseReviewDrawer}
                panelRef={editPanelRef}
                matchedQuestion={selectedReviewItem.matchedQuestion}
                contentClassName="px-4"
              />
              <MainstayDrawerFooter>
                <>
                  <div className="mb-2 mainstay-header-h5">
                    Finished with maintenance?
                  </div>
                  <Button
                    color="primary"
                    loadingText="Loading..."
                    onClick={handleCloseReviewDrawer}>
                    Close
                  </Button>
                </>
              </MainstayDrawerFooter>
            </MainstayDrawer>
          ) : null}
        </>
      </MainstayFlexTable>
      {totalCount > 0 && (
        <Pager
          first={1}
          current={page}
          last={Math.max(Math.ceil(totalCount / KB_REVIEW_ITEMS_PER_PAGE), 1)}
          urlFormat={x => `${appendQueryFilter(window.location, 'page', x)}`}
        />
      )}
    </>
  )
}

export default KnowledgeReviewTable
