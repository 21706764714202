import {
  ICampaignScriptPreview,
  PromptType,
  IntroDialogKind,
} from 'store/campaign-scripts/reducer'
import { isRight } from 'fp-ts/Either'

import {
  ICampaignScriptStateSpliceRequest,
  ICampaignScriptStateDeleteEdgeRequest,
  ICampaignScriptStateUpdateRequest,
  ICampaignScriptUpdateRequest,
} from 'api/request'
import { Dispatch } from 'store/store'
import {
  spliceCampaignScriptState,
  deleteCampaignScriptStateEdge,
  updateCampaignScriptState,
  duplicateCampaignScript,
  destroyCampaignScript,
  updateCampaignScript,
  createCampaignScript,
  searchCampaignScripts,
  retrieveCampaignScript,
  getAllCampaignScripts,
  fetchTestContacts,
  createLink,
  ICampaignScriptCreateLinkPayload,
  stopLinking,
  createCampaignScriptInitialState,
  fetchIntroDialogsList,
  fetchIntroDialogDetails,
  generateDialogReport,
  generateDialogReportProgress,
  copyFromLibrary,
  copyToLibrary,
  updateTemplateCollections,
  searchScriptTemplates,
  refreshDialogUpdatedAt,
  editScriptNodeAction,
} from 'store/campaign-scripts/actions'
import * as Api from 'api'
import { AxiosError } from 'axios'
import { toastOnHttpError500or400 } from 'api/http'
import { createScheduledMessage } from 'store/campaign-scheduler/actions'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { openDownloadURL } from 'util/links'
import { timeout } from 'util/timeout'
import * as Raven from '@sentry/browser'
import { hasQueryFilter, getQueryFilters } from 'util/queryFilters'
import { sendEvent } from 'api/events'
import { IPreferencesRequestPayload } from 'components/TrendsV2/CustomizedDownloadModal'
import { noOp } from 'util/noOp'
import { IScriptUsages } from 'api/response'

const getLabelFilter = (location: Location) => {
  if (hasQueryFilter(location, 'label')) {
    const queryParams = getQueryFilters(location)
    const labelFilter = queryParams['label']
    if (typeof labelFilter === 'string') {
      return labelFilter
    }
  }
}

export const searchCampaignScriptsAsync = (dispatch: Dispatch) => {
  return (
    searchQuery: string = '',
    page: number = 1,
    sortField: keyof ICampaignScriptPreview = 'createdAt',
    order: 'asc' | 'desc' = 'desc'
  ) => {
    dispatch(searchCampaignScripts.request(searchQuery))
    const label = getLabelFilter(window.location)
    sendEvent('search:campaign-scripts', {
      searchQuery,
      page,
      sortField,
      order,
      label: label || null,
    })
    return Api.listCampaignScripts(searchQuery, page, sortField, order, label)
      .then(x => {
        dispatch(searchCampaignScripts.success(x.data))
      })
      .catch((err: AxiosError) => {
        dispatch(searchCampaignScripts.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const searchTemplateScriptsAsync = (dispatch: Dispatch) => {
  return (
    searchQuery: string = '',
    page: number = 1,
    sortField: keyof ICampaignScriptPreview = 'createdAt',
    order: 'asc' | 'desc' = 'desc',
    status?: 'published' | 'unpublished',
    collection?: number,
    labels?: string[],
    topic?: string
  ) => {
    dispatch(searchScriptTemplates.request(searchQuery))
    return Api.listTemplateScripts(
      searchQuery,
      page,
      sortField,
      order,
      status,
      collection,
      labels,
      topic
    )
      .then(x => {
        dispatch(
          searchScriptTemplates.success({
            count: x.data.count,
            results: x.data.results,
          })
        )
      })
      .catch((err: AxiosError) => {
        dispatch(searchScriptTemplates.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const retrieveCampaignScriptAsync = (dispatch: Dispatch) => {
  return (id: string, isTemplate?: boolean) => {
    dispatch(retrieveCampaignScript.request(id))
    return Api.retrieveCampaignScripts(id, isTemplate)
      .then(x => {
        dispatch(retrieveCampaignScript.success(x.data))
      })
      .catch((err: AxiosError) => {
        dispatch(retrieveCampaignScript.failure(err))
        toastOnHttpError500or400(err, 3000, {
          404: `Script with ID ${id} not found`,
        })
      })
  }
}

export const getAllCampaignScriptPreviewsAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(getAllCampaignScripts.request())
    return Api.getAllCampaignScriptPreviews()
      .then(x => {
        dispatch(getAllCampaignScripts.success(x.data))
      })
      .catch((err: AxiosError) => {
        dispatch(getAllCampaignScripts.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const spliceCampaignScriptStateAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptStateSpliceRequest
) => {
  dispatch(spliceCampaignScriptState.request(request))
  return Api.campaignScriptStateSplice(request)
    .then(res => {
      dispatch(
        spliceCampaignScriptState.success({
          dialogStateId: request.dialogStateId,
          transitionType: request.data.transitionType,
          spliceTransition: request.data.spliceTransition,
          newDialogStateData: res.data.newDialogState,
        })
      )
      dispatch(refreshDialogUpdatedAt())
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(spliceCampaignScriptState.failure())
    })
}

export const deleteCampaignScriptStateEdgeAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptStateDeleteEdgeRequest
) => {
  dispatch(deleteCampaignScriptStateEdge.request(request))
  return Api.campaignScriptStateDeleteEdge(request)
    .then(() => {
      dispatch(deleteCampaignScriptStateEdge.success(request))
      dispatch(refreshDialogUpdatedAt())
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(deleteCampaignScriptStateEdge.failure())
    })
}

export const editScriptNode = (dispatch: Dispatch) => ({
  dialogStateId,
  editing,
}: {
  dialogStateId: string
  editing: boolean
}) => dispatch(editScriptNodeAction({ dialogStateId, editing }))

export const updateCampaignScriptStateAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptStateUpdateRequest
) => {
  dispatch(updateCampaignScriptState.request(request))
  return Api.campaignScriptStateUpdate(request)
    .then(res => {
      dispatch(updateCampaignScriptState.success(res.data))
      dispatch(refreshDialogUpdatedAt())
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(updateCampaignScriptState.failure())
    })
}

export const linkCampaignScriptStateAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptCreateLinkPayload
) => {
  dispatch(createLink.request(request))
  return Api.campaignScriptStateUpdate(request)
    .then(res => {
      dispatch(
        createLink.success({
          dialogId: request.dialogId,
          data: res.data,
          dialogStateId: request.dialogStateId,
          linkTransitionKey: request.linkTransitionKey,
        })
      )
      dispatch(stopLinking())
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(createLink.failure())
    })
}

export const duplicateCampaignScriptAsync = (dispatch: Dispatch) => (
  id: string,
  humanName: string,
  labelsToAdd: number[],
  labelsToCreate: string[]
) => {
  dispatch(duplicateCampaignScript.request(id))
  return Api.campaignScriptDuplicate(id, humanName, labelsToAdd, labelsToCreate)
    .then(res => {
      toast('Campaign script duplicated', { type: 'success' })
      dispatch(duplicateCampaignScript.success(res.data))
      return Promise.resolve(res.data.dialog.id)
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(duplicateCampaignScript.failure())
      return Promise.reject()
    })
}

export const destroyCampaignScriptAsync = (dispatch: Dispatch) => (
  id: string,
  isTemplate?: boolean
) => {
  dispatch(destroyCampaignScript.request(id))
  return Api.campaignScriptDestroy(id, isTemplate)
    .then(() => {
      toast('Campaign script archived', { type: 'success' })
      dispatch(destroyCampaignScript.success(id))
      return undefined
    })
    .catch((e: AxiosError<{ detail?: string; usages?: IScriptUsages }>) => {
      if (!e.response?.data.usages) {
        toastOnHttpError500or400(e)
      }
      dispatch(destroyCampaignScript.failure())
      return e.response?.data.usages
    })
}

export const updateCampaignScriptAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptUpdateRequest,
  isTemplate?: boolean
) => {
  dispatch(updateCampaignScript.request(request))
  return Api.campaignScriptUpdate(request, isTemplate)
    .then(res => {
      dispatch(updateCampaignScript.success(res.data))
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(updateCampaignScript.failure())
    })
}

export const createCampaignScriptAsync = (dispatch: Dispatch) => (
  isTemplate?: boolean
) => {
  dispatch(createCampaignScript.request())
  return Api.campaignScriptCreate(isTemplate)
    .then(res => {
      dispatch(createCampaignScript.success(res.data))
      return Promise.resolve(res.data)
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(createCampaignScript.failure())
      return Promise.reject(e)
    })
}

export const fetchTestContactsAsync = (dispatch: Dispatch) => {
  dispatch(fetchTestContacts.request())
  return Api.fetchTestContacts().then(res => {
    if (isRight(res)) {
      dispatch(fetchTestContacts.success(res.right))
    } else {
      dispatch(fetchTestContacts.failure())
    }
  })
}

export const testCampaignScriptAsync = (dispatch: Dispatch) => {
  return (name: string, dialogId: string, studentIds: string[]) => {
    const requestData = {
      name: `${name} test`,
      description: `Campaign script '${name}'`,
      dialogId,
      hidden: true,
      test: true,
      immediate: true,
      users: studentIds,
    }
    dispatch(createScheduledMessage.request(requestData))
    return Api.createScheduledMessage(requestData)
      .then(() => {
        dispatch(createScheduledMessage.success())
        toast('Campaign script sent', { type: 'success' })
      })
      .catch((err: AxiosError) => {
        dispatch(createScheduledMessage.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const createCampaignScriptInitialStateAsync = (dispatch: Dispatch) => (
  dialogId: string,
  promptType: PromptType
) => {
  dispatch(createCampaignScriptInitialState.request())
  return Api.createScriptInitialState(dialogId, promptType)
    .then(res => {
      dispatch(createCampaignScriptInitialState.success(res.data))
      return Promise.resolve(res.data)
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(createCampaignScriptInitialState.failure())
      return Promise.reject(e)
    })
}

export const fetchIntroDialogsListAsync = (dispatch: Dispatch) => () => {
  dispatch(fetchIntroDialogsList.request())
  return Api.getIntroDialogs()
    .then(r => {
      dispatch(fetchIntroDialogsList.success(r.data))
    })
    .catch(() => {
      dispatch(fetchIntroDialogsList.failure())
    })
}

export const fetchIntroDialogDetailsAsync = (dispatch: Dispatch) => (
  kind: IntroDialogKind
) => {
  dispatch(fetchIntroDialogDetails.request())
  return Api.getIntroDialogDetails(kind)
    .then(r => {
      dispatch(fetchIntroDialogDetails.success(r.data))
    })
    .catch((_e: AxiosError) => {
      dispatch(fetchIntroDialogDetails.failure())
    })
}

const dialogReportPollAsync = (
  dialogId: string,
  taskId: string,
  onReportDownloadComplete: null | (() => void),
  ms = 3000
) => (dispatch: Dispatch) => {
  Api.getDialogStatusById(taskId)
    .then(async res => {
      if (res.data.status === 'SUCCESS') {
        dispatch(generateDialogReport.success({ dialogId }))
        onReportDownloadComplete ? onReportDownloadComplete() : noOp()
        toast('Report successfully downloaded!', { type: 'success' })
        openDownloadURL({
          url: res.data.report_url,
        })
      } else {
        dispatch(
          generateDialogReportProgress({
            dialogId,
            progress: res.data.progress,
          })
        )
        await timeout(ms)
        dialogReportPollAsync(
          dialogId,
          taskId,
          onReportDownloadComplete
        )(dispatch)
      }
    })
    .catch((e: AxiosError) => {
      handleReportError(e, dialogId)(dispatch)
    })
}

export const generateDialogReportAsync = (
  dialogId: string,
  startDate: null | Date,
  endDate: null | Date,
  commandId: null | number,
  preferences: null | IPreferencesRequestPayload = null,
  campaignReportType: null | string = 'dialog_report',
  onReportDownloadComplete: null | (() => void)
) => (dispatch: Dispatch) => {
  dispatch(generateDialogReport.request({ dialogId }))
  return Api.generateDialogReport(
    dialogId,
    startDate,
    endDate,
    commandId,
    preferences,
    campaignReportType
  )
    .then(res => {
      const taskId = res.data.task_id
      dialogReportPollAsync(
        dialogId,
        taskId,
        onReportDownloadComplete
      )(dispatch)
    })
    .catch((e: AxiosError) => {
      handleReportError(e, dialogId)(dispatch)
    })
}

const handleReportError = (e: AxiosError, dialogId: string) => (
  dispatch: Dispatch
) => {
  dispatch(generateDialogReport.failure({ dialogId }))
  toast(
    'There was a problem downloading this report. Please try again later.',
    { type: 'error' }
  )
  Raven.captureException(e)
}

export const copyToLibraryAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptUpdateRequest
) => {
  dispatch(copyToLibrary.request(request))
  return Api.copyToScriptLibrary(request.id, request.data?.humanName)
    .then(res => {
      dispatch(copyToLibrary.success(res.data))
      toast('Succesfully created draft.', {
        type: 'success',
      })
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(copyToLibrary.failure())
    })
}

export const copyFromLibraryAsync = (dispatch: Dispatch) => (
  request: ICampaignScriptUpdateRequest,
  labelsToAdd: number[],
  labelsToCreate: string[]
) => {
  dispatch(copyFromLibrary.request(request))
  return Api.copyFromScriptLibrary(
    request.id,
    request.data?.humanName,
    labelsToAdd,
    labelsToCreate
  )
    .then(res => {
      dispatch(copyFromLibrary.success(res.data))
      toast('Succesfully copied the script to your library.', {
        type: 'success',
      })
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(copyFromLibrary.failure())
    })
}

export const updateTemplateCollectionsAsync = (dispatch: Dispatch) => {
  return (
    collectionsToAdd: Api.ITemplateCollection[],
    collectionsToRemove: Api.ITemplateCollection[],
    templateIds: string[]
  ) => {
    dispatch(updateTemplateCollections.request())
    return Api.updateTemplateCollections(
      collectionsToAdd.map(c => c.id),
      collectionsToRemove.map(c => c.id),
      templateIds
    )
      .then(() => {
        dispatch(
          updateTemplateCollections.success({
            templateIds,
            collectionsToAdd,
            collectionsToRemove,
          })
        )
      })
      .catch((e: AxiosError) => {
        toastOnHttpError500or400(e)
        dispatch(updateTemplateCollections.failure(e))
      })
  }
}
