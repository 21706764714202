import 'components/CampaignTrigger/CampaignTrigger.scss'
import moment from 'moment-timezone'
import * as React from 'react'
import 'react-day-picker/lib/style.css'

import { Button } from 'components/Button/Button'
import { Link } from 'util/routing'
import { Checkbox } from '@material-ui/core'
import { ICampaignSendWindow } from 'api/response'
import { CampaignHoursForm } from 'components/CampaignHoursForm/CampaignHoursForm'
import { Dropdown, IDropdownItem } from 'components/Dropdown/Dropdown'
import { CAMPAIGN_HISTORY } from 'const/routes'
import { InlineDateTimePicker } from 'components/InlineDateTimePicker/InlineDateTimePicker'
import classnames from 'classnames'
import {
  DayHourMinutePicker,
  TimeUnit,
} from 'components/DurationPicker/DurationPicker'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from 'components/MaterialComponents/MaterialComponents'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import { DHMToSeconds } from 'util/timeConversion'
import { isNaN } from 'lodash'
import {
  ICampaignSchedulerFormData,
  IRecurrenceSettings,
  IDataTriggeredSettings,
  TriggerType,
  MINIMUM_DTC_MINUTES_DELAY,
  StudentSelectionType,
} from 'store/campaign-scheduler/reducer'
import { RepeatInterval } from 'util/dateRepeatIntervals'
import { areIntervalsOverlapping } from 'date-fns'

export interface ICampaignTriggerProps {
  onUpdateFormData: (
    formData: Partial<ICampaignSchedulerFormData>,
    timezone?: string
  ) => void
  triggerType: TriggerType
  date: string | undefined
  recurrenceSettings?: IRecurrenceSettings
  dataTriggeredSettings?: IDataTriggeredSettings
  studentSelectionType: StudentSelectionType
  globalAppropriateTimeSettings: ICampaignSendWindow
  repeatIntervals: IDropdownItem[]
  navToContactSelection: () => void
  params: { id: string }
  path: string
}

const dateFormatString = moment.HTML5_FMT.DATE
const timeFormatString = moment.HTML5_FMT.TIME_SECONDS

export const RecurringCampaignEndDatePicker = ({
  isInfinite,
  endDateString,
  disableDaysBefore,
  handleRepeatEndDateChange,
  handleRepeatinfiniteChange,
}: {
  isInfinite: boolean
  endDateString: string | undefined
  disableDaysBefore: Date
  handleRepeatEndDateChange: (e: Date) => void
  handleRepeatinfiniteChange: (isInfinite: boolean) => void
}) => {
  const dateValue = isInfinite ? "Doesn't End" : endDateString

  return (
    <>
      <p className="caption text-muted mb-1">End Date</p>
      <div className="d-flex align-items-end">
        {!isInfinite && (
          <div className="d-flex flex-column max-width-200 mr-2">
            <ReadOnlyDatePicker
              value={dateValue}
              onDayChange={handleRepeatEndDateChange}
              dayPickerProps={{
                disabledDays: {
                  before: disableDaysBefore,
                },
                month: getStartMonth(),
              }}
              classNames={{
                container: 'DayPickerInput-Container mr-1',
                overlayWrapper: 'DayPickerInput-OverlayWrapper',
                overlay: 'DayPickerInput-Overlay',
              }}
            />
          </div>
        )}
        <div className="d-flex my-2">
          <Checkbox
            id="infinite"
            color="primary"
            checked={isInfinite}
            name="infinite"
            className="p-0 mr-2"
            onChange={e => handleRepeatinfiniteChange(e.target.checked)}
          />
          <label htmlFor="infinite" className="m-0">
            <p className="text-mainstay-dark-blue-65 my-0">Doesn't End</p>
          </label>
        </div>
      </div>
    </>
  )
}

export const CampaignTrigger = ({
  date,
  path,
  params,
  onUpdateFormData,
  triggerType,
  recurrenceSettings,
  dataTriggeredSettings,
  globalAppropriateTimeSettings,
  studentSelectionType,
  repeatIntervals,
  navToContactSelection,
}: ICampaignTriggerProps) => {
  const institutionTimezone = globalAppropriateTimeSettings.timezone

  const alignRecurringEndDateWithStartDate = (
    startDate?: string | Date,
    endDate?: string | Date
  ) => {
    if (!endDate) {
      return
    }
    const momentStartDate = moment.utc(startDate)

    const time = moment(endDate).format(timeFormatString) // format this to time only part of UTC
    const formattedDate = moment
      .tz(endDate, institutionTimezone)
      .format(dateFormatString) // format to date only part of string

    const timeZoneDate = moment.tz(
      `${formattedDate}T${time}`,
      institutionTimezone
    )

    const momentEndDate = moment
      .utc(timeZoneDate)
      .hour(momentStartDate.hours())
      .minute(momentStartDate.minutes())
      .second(momentStartDate.seconds())

    return momentEndDate.format(moment.defaultFormatUtc)
  }

  const handleTimeChange = (e: moment.Moment | null) => {
    if (e == null) {
      return
    }

    const time = e.format(timeFormatString) // format this to time only part of UTC
    const formattedDate = moment
      .tz(date, institutionTimezone)
      .format(dateFormatString) // format to date only part of string
    const timeZoneDate = moment.tz(
      `${formattedDate}T${time}`,
      institutionTimezone
    )
    const newDateTime = timeZoneDate.utc().format(moment.defaultFormatUtc) // format to utc

    const recurringEndDate = alignRecurringEndDateWithStartDate(
      newDateTime,
      recurrenceSettings?.endDate
    )
    onUpdateFormData(
      {
        date: newDateTime,
        recurrenceSettings: {
          ...recurrenceSettings,
          startDate: newDateTime,
          endDate: recurringEndDate,
        },
      },
      institutionTimezone
    )
  }

  React.useEffect(() => {
    const startDate = recurrenceSettings?.startDate
    const endDate = recurrenceSettings?.endDate
    // If user select a start date that is after or the same as the end date, clear out the end date and force user to re-pick
    if (
      startDate &&
      endDate &&
      (moment(endDate).isBefore(startDate) ||
        moment(startDate).isSame(endDate, 'day'))
    ) {
      onUpdateFormData(
        {
          recurrenceSettings: {
            ...recurrenceSettings,
            endDate: undefined,
          },
        },
        institutionTimezone
      )
    }
  }, [
    recurrenceSettings?.endDate,
    recurrenceSettings?.startDate,
    recurrenceSettings,
    onUpdateFormData,
    institutionTimezone,
  ])

  const handleDayChange = (e: Date) => {
    const existingTime = moment
      .tz(date, institutionTimezone)
      .format(timeFormatString) // append the already set time to new date so we aren't clearing out the time every time we choose a new date

    const formattedDate = moment
      .tz(e, institutionTimezone)
      .format(dateFormatString) // format this to date only part of UTC
    const newDateTime = moment.tz(
      `${formattedDate}T${existingTime}`,
      institutionTimezone
    )

    const startDate = newDateTime.utc().format(moment.defaultFormatUtc) // format to utc
    const recurringEndDate = alignRecurringEndDateWithStartDate(
      startDate,
      recurrenceSettings?.endDate
    )

    onUpdateFormData(
      {
        date: startDate,
        recurrenceSettings: {
          ...recurrenceSettings,
          startDate,
          endDate: recurringEndDate,
        },
      },
      institutionTimezone
    )
  }

  const handleRadioChange = (e: React.ChangeEvent<{}>, v: string) => {
    /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
    const triggerType = v as TriggerType
    onUpdateFormData(
      {
        triggerType,
      },
      institutionTimezone
    )

    if (triggerType === TriggerType.Recurring) {
      handleRepeatIntervalChange({ value: RepeatInterval.daily })
    }

    if (
      triggerType === TriggerType.DataTriggered &&
      studentSelectionType !== StudentSelectionType.ContactSegment
    ) {
      onUpdateFormData(
        {
          importLabels: [],
          studentSelectionType: StudentSelectionType.None,
          multiContactFilters: [],
          importAutoLabel: '',
          selectedAutoLabels: [],
        },
        institutionTimezone
      )
    }

    return e
  }

  const handleRepeatEndDateChange = (e: Date): void => {
    const startDate = recurrenceSettings?.startDate
    const endDate = alignRecurringEndDateWithStartDate(startDate, e)

    onUpdateFormData(
      {
        recurrenceSettings: {
          ...recurrenceSettings,
          endDate,
          infinite: false,
        },
      },
      institutionTimezone
    )
  }

  const handleRepeatIntervalChange = ({
    value,
  }: Omit<IDropdownItem, 'label'>): void => {
    const endDate =
      value === RepeatInterval.noRepeat
        ? undefined
        : recurrenceSettings?.endDate

    // When toggling from non-interval to interval, we don't want sendCampaignOnce to be set in the form until toggled on/off
    delete recurrenceSettings?.sendCampaignOnce

    onUpdateFormData(
      {
        recurrenceSettings: {
          ...recurrenceSettings,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          interval: value as RepeatInterval,
          endDate,
        },
      },
      institutionTimezone
    )
  }

  const getDropdownValue = (): IDropdownItem | undefined => {
    const interval = recurrenceSettings?.interval
    if (!interval) {
      return repeatIntervals[0]
    }

    if (!areIntervalsOverlapping) {
      return repeatIntervals[0]
    }

    return repeatIntervals.find(({ value }) => interval === value)
  }

  const sendCampaignOnce = (
    triggerType: TriggerType.DataTriggered | TriggerType.Recurring
  ): boolean => {
    if (triggerType === TriggerType.DataTriggered) {
      return dataTriggeredSettings?.sendCampaignOnce || false
    }

    return recurrenceSettings?.sendCampaignOnce || false
  }

  const handleSendCampaignOnceChange = (
    triggerType: TriggerType.DataTriggered | TriggerType.Recurring
  ) => {
    const currentValue = sendCampaignOnce(triggerType)

    const [settingsObj, settingsFieldName] =
      triggerType === TriggerType.Recurring
        ? [recurrenceSettings, 'recurrenceSettings']
        : triggerType === TriggerType.DataTriggered
        ? [dataTriggeredSettings, 'dataTriggeredSettings']
        : []

    if (!settingsFieldName) {
      return
    }
    onUpdateFormData(
      {
        [settingsFieldName]: {
          ...settingsObj,
          sendCampaignOnce: !currentValue,
        },
      },
      institutionTimezone
    )
  }

  const handleRepeatinfiniteChange = (isInfinite: boolean) => {
    onUpdateFormData(
      {
        recurrenceSettings: {
          ...recurrenceSettings,
          infinite: isInfinite,
          endDate: undefined,
        },
      },
      institutionTimezone
    )
  }

  const handleDataTriggeredTimeDelayChange = (
    unit: TimeUnit,
    value: number
  ) => {
    let newVal = value

    // If you remove value from input, we don't want to save NaN to form, so save 0
    if (isNaN(value)) {
      newVal = 0
    }

    onUpdateFormData(
      {
        dataTriggeredSettings: {
          ...dataTriggeredSettings,
          timeDelay: {
            ...dataTriggeredSettings?.timeDelay,
            [unit]: newVal,
          },
        },
      },
      institutionTimezone
    )
  }

  const dataTriggeredHoursError = () => {
    if (dataTriggeredSettings?.timeDelay) {
      return (
        DHMToSeconds(dataTriggeredSettings?.timeDelay) <
        MINIMUM_DTC_MINUTES_DELAY * 60
      )
    }
  }

  const isDTCEdit = Boolean(
    params.id && path.includes(CAMPAIGN_HISTORY.EDIT_DATA_TRIGGERED_DETAIL)
  )

  const triggerOptionEnabled = Boolean(!params.id || isDTCEdit)

  const label = 'Trigger'
  const dropdownValue = getDropdownValue()
  const dateFromString = date
    ? moment(date)
        .tz(institutionTimezone)
        .format('MMMM DD, YYYY')
    : undefined
  return (
    <div>
      <FormControl>
        <p className="text-muted">
          Choose when this campaign will be sent to recipients
        </p>
        <RadioGroup
          aria-label={label}
          name={label}
          value={triggerType}
          onChange={handleRadioChange}>
          <FormControlLabel
            label={
              <span>
                Send <strong>immediately</strong>
              </span>
            }
            value={TriggerType.Immediate}
            control={<Radio color="primary" />}
            className={classnames('font-family-source-sans-pro', {
              'disabled-option': isDTCEdit,
            })}
          />
          <FormControlLabel
            label={
              <span>
                Send <strong>once</strong> on a specific date and time
              </span>
            }
            value={TriggerType.SpecificDate}
            className={classnames('font-family-source-sans-pro', {
              'disabled-option': isDTCEdit,
            })}
            control={<Radio color="primary" />}>
            <div className="d-flex trigger-type-group">
              <InlineDateTimePicker
                date={date}
                institutionTimezone={institutionTimezone}
                handleDayChange={handleDayChange}
                handleTimeChange={handleTimeChange}
              />
              <div className="mt-4">{institutionTimezone}</div>
            </div>
          </FormControlLabel>
          <FormControlLabel
            label={
              <span>
                Send on a <strong>recurring</strong> schedule
              </span>
            }
            value={TriggerType.Recurring}
            className={classnames('font-family-source-sans-pro', {
              'disabled-option': isDTCEdit,
            })}
            control={<Radio color="primary" />}>
            <div className="trigger-type-group">
              <div className="d-flex align-items-center">
                <InlineDateTimePicker
                  date={date}
                  institutionTimezone={institutionTimezone}
                  handleDayChange={handleDayChange}
                  handleTimeChange={handleTimeChange}
                />
                <div className="mt-4">{institutionTimezone}</div>
              </div>
              <div className="d-flex flex-column max-width-400 repeat-interval-dropdown my-3">
                <p className="caption text-muted mb-1">Repeats</p>
                <Dropdown
                  onSelect={handleRepeatIntervalChange}
                  items={repeatIntervals}
                  value={dropdownValue}
                />
              </div>
              {dropdownValue?.value &&
                dropdownValue?.value !== RepeatInterval.noRepeat && (
                  <RecurringCampaignEndDatePicker
                    isInfinite={!!recurrenceSettings?.infinite}
                    endDateString={dateFromString}
                    disableDaysBefore={moment(dateFromString)
                      .add(1, 'day')
                      .startOf('day')
                      .toDate()}
                    handleRepeatEndDateChange={handleRepeatEndDateChange}
                    handleRepeatinfiniteChange={handleRepeatinfiniteChange}
                  />
                )}
              {dropdownValue?.value &&
                dropdownValue?.value !== RepeatInterval.noRepeat && (
                  <div className="d-flex mt-3">
                    <Checkbox
                      id="sendCampaignOnce"
                      color="primary"
                      checked={sendCampaignOnce(TriggerType.Recurring)}
                      name="sendCampaignOnce"
                      className="p-0 mr-2"
                      onClick={() =>
                        handleSendCampaignOnceChange(TriggerType.Recurring)
                      }
                    />
                    <label htmlFor="sendCampaignOnce" className="m-0">
                      <p className="text-mainstay-dark-blue-65 my-0">
                        Send to audience contacts one time only
                      </p>
                    </label>
                  </div>
                )}
            </div>
          </FormControlLabel>

          <FormControlLabel
            label={
              <span>
                Send when a contact <strong>enters</strong> this Audience
                (starts meeting the criteria)
              </span>
            }
            value={TriggerType.DataTriggered}
            className={classnames('font-family-source-sans-pro', {
              'disabled-option': !triggerOptionEnabled,
            })}
            control={<Radio color="primary" />}>
            <div className="trigger-type-group">
              <div className="d-flex flex-column">
                <div>
                  <p className="caption text-muted mb-1">Send after...</p>
                  <div className="d-flex">
                    <DayHourMinutePicker
                      onChange={handleDataTriggeredTimeDelayChange}
                      ignoreErrorsOnChange={true}
                      days={dataTriggeredSettings?.timeDelay?.days || 0}
                      hours={dataTriggeredSettings?.timeDelay?.hours || 0}
                      minutes={
                        !dataTriggeredSettings?.timeDelay?.minutes &&
                        dataTriggeredSettings?.timeDelay?.minutes !== 0
                          ? MINIMUM_DTC_MINUTES_DELAY
                          : dataTriggeredSettings?.timeDelay?.minutes
                      }
                      error={dataTriggeredHoursError()}
                    />
                  </div>
                  {dataTriggeredHoursError() && (
                    <p className="text-danger">
                      Time delay must be at least {MINIMUM_DTC_MINUTES_DELAY}{' '}
                      minutes
                    </p>
                  )}
                </div>
                <div className="d-flex mt-3">
                  <Checkbox
                    id="sendDataTriggeredCampaignOnce"
                    color="primary"
                    checked={sendCampaignOnce(TriggerType.DataTriggered)}
                    name="sendDataTriggeredCampaignOnce"
                    className="p-0 mr-2"
                    onClick={() =>
                      handleSendCampaignOnceChange(TriggerType.DataTriggered)
                    }
                  />
                  <label
                    htmlFor="sendDataTriggeredCampaignOnce"
                    className="m-0">
                    <p className="text-mainstay-dark-blue-65 my-0">
                      Send to audience contacts one time only
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </FormControlLabel>
        </RadioGroup>
        <div className="p-3">
          <div className="m-0 mainstay-header-h4 text-mainstay-dark-blue-80">
            Allowed Hours
          </div>
          <p>
            To prevent late night texts, messages scheduled outside of these
            hours will send the next day. Configure these hours in{' '}
            <Link to="/settings/sms">SMS / Text Message Settings</Link>
          </p>
          <CampaignHoursForm
            readonlyHours={true}
            campaignSendWindow={globalAppropriateTimeSettings}
          />
        </div>
      </FormControl>

      <div className="d-flex mt-3 justify-content-center">
        <Button
          className="btn btn-secondary-teal px-3 py-2"
          onClick={navToContactSelection}>
          Next
        </Button>
      </div>
    </div>
  )
}

function getStartMonth(): Date {
  // end date should be at least 1 day after start date for
  // recurring campaigns. If it's the last day of the month
  // datepicker should open starting with the next month
  return moment()
    .endOf('month')
    .format('YYYY-DD-MM') === moment().format('YYYY-DD-MM')
    ? moment()
        .add(1, 'day')
        .toDate()
    : moment().toDate()
}
