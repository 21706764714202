import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'util/routing'
import classnames from 'classnames'
import { findIndex } from 'lodash'
import 'components/TabbedView/TabbedView.scss'
import { EventAction } from 'components/EventTracker/EventTracker'
import PermissionGuard from 'util/permissions/PermissionGuard'

export interface ITabbedViewOption {
  label: React.ReactNode
  component: JSX.Element
  hashParam?: string
  customLink?: string
  className?: string
  eventLocation?: string
  eventObject?: string
  eventAction?: EventAction
  permission?: string
}

interface ITabbedViewProps extends RouteComponentProps<{}> {
  className?: string
  activeClassname?: string
  tabClassName?: string
  tabCollectionClassName?: string
  tabContentClassName?: string
  options: ITabbedViewOption[]
  selectedTab?: string
  unknownTabContent?: React.ReactNode
  useHash?: boolean
}

const TabbedView = ({
  className,
  activeClassname,
  tabClassName,
  tabCollectionClassName,
  tabContentClassName,
  options,
  selectedTab,
  location,
  unknownTabContent,
  useHash = true,
}: ITabbedViewProps) => {
  const allowUnknownTabs = !!unknownTabContent
  const findSelectedIndex = React.useCallback(
    (selected?: string) => {
      const defaultIndex = allowUnknownTabs ? -1 : 0
      const target = selected || location.hash.slice(1)
      const index = findIndex(options, x => x.hashParam === target)
      return index === -1 ? defaultIndex : index
    },
    [location.hash, options, allowUnknownTabs]
  )
  const [index, setIndex] = React.useState<number>(
    findSelectedIndex(selectedTab)
  )
  const [existingHash, setHash] = React.useState<string>('')

  React.useEffect(() => {
    if (useHash && location.hash && existingHash !== location.hash) {
      setHash(location.hash)
      setIndex(findSelectedIndex())
    }
    if (selectedTab) {
      setIndex(findSelectedIndex(selectedTab))
    }
  }, [selectedTab, findSelectedIndex, existingHash, location.hash, useHash])

  return (
    <div
      className={classnames(
        'd-flex flex-column w-100 panel panel-main',
        className
      )}>
      <div
        className={classnames(
          'd-flex align-items-center flex-shrink-0 panel-header ',
          tabCollectionClassName
        )}>
        <div className="border-bottom panel-header-content w-100 d-flex align-items-end ">
          {options.map((o, i) => {
            return (
              <PermissionGuard
                className="py-2"
                key={`perm-guard-${o.customLink || o.hashParam}`}
                permission={o.permission}>
                <Link
                  replace={true}
                  className={classnames(
                    'tab-link',
                    {
                      [`active ${activeClassname}`]: i === index,
                    },
                    tabClassName
                  )}
                  key={o.customLink || o.hashParam}
                  onClick={() => {
                    // Only setIndex if using hash. For URL paths, routing happens through directly through the Link component.
                    if (useHash) {
                      setIndex(findSelectedIndex())
                    }
                  }}
                  to={o.customLink || `#${o.hashParam}`}
                  eventAction={o.eventAction}
                  eventObject={o.eventObject}
                  eventLocation={o.eventLocation}>
                  {o.label}
                </Link>
              </PermissionGuard>
            )
          })}
        </div>
      </div>
      {index === -1 && unknownTabContent}
      <div
        className={classnames(
          'panel-content h-100',
          options[index]?.className,
          tabContentClassName
        )}>
        {options[index]?.component}
      </div>
    </div>
  )
}

export default withRouter(TabbedView)
