import {
  Failure,
  isFailure,
  isLoading,
  isRefetching,
  isSuccessOrRefetching,
  Loading,
  Refetching,
  Success,
  WebData,
} from 'store/webdata'
import * as api from 'api'
import React from 'react'
import {
  ISearchResult,
  SearchResult,
} from 'components/UnderstandingCreatePanel/UnderstandingCreatePanel'
import 'page/knowledge-base/KnowledgeReview/AllMessagesDrawer/SearchUnderstandings/SearchUnderstandings.scss'
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios'
import { useDebounce } from 'util/hooks'
import { SearchInput } from 'components/SearchInput/SearchInput'
import classNames from 'classnames'
const MIN_CHARACTER_COUNT_FOR_SEARCH = 3

function useSearchResults() {
  const [state, setState] = React.useState<
    WebData<ReadonlyArray<ISearchResult>>
  >(undefined)
  const handleSearch = React.useCallback(
    (query: string, cancelToken?: CancelToken) => {
      if (
        query.length &&
        query.trim().length < MIN_CHARACTER_COUNT_FOR_SEARCH
      ) {
        return
      }
      setState(s => {
        if (isSuccessOrRefetching(s)) {
          return Refetching(s.data)
        }
        return Loading()
      })
      api
        .searchQuestionsV2({ question: query }, cancelToken)
        .then(res => {
          setState(Success(res.data.results))
        })
        .catch((err: AxiosError) => {
          if (axios.isCancel(err)) {
            setState(Loading())
            return
          }
          setState(Failure(undefined))
        })
    },
    []
  )

  return [state, handleSearch] as const
}

export function SearchUnderstandings({
  defaultSearch,
  onEdit,
}: {
  readonly defaultSearch: string
  readonly onEdit: (understandingId: string) => void
}) {
  const [query, setQuery] = React.useState(defaultSearch || '')
  const [searchResults, handleSearch] = useSearchResults()
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined)
  const debouncedQuery = useDebounce(query, 600)

  React.useEffect(() => {
    if (debouncedQuery) {
      if (cancelTokenRef?.current) {
        cancelTokenRef.current.cancel()
      }
      cancelTokenRef.current = axios.CancelToken.source()
      handleSearch(debouncedQuery, cancelTokenRef.current.token)
    }
  }, [debouncedQuery, handleSearch])

  function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value
    setQuery(query)
  }

  return (
    <>
      <SearchInput
        className="mt-3 mb-4"
        placeholder="Your question here..."
        onChange={handleQueryChange}
        value={query}
        minCharactersCount={MIN_CHARACTER_COUNT_FOR_SEARCH}
      />
      {isLoading(searchResults) || isRefetching(searchResults) ? (
        <p>Searching...</p>
      ) : null}
      {isFailure(searchResults) ? (
        <p>Search failed. Please try again.</p>
      ) : null}
      {query.trim().length > 0 && isSuccessOrRefetching(searchResults) ? (
        <div
          className={classNames({ 'opacity-50': isRefetching(searchResults) })}>
          <div className="mainstay-header-h5">
            There are {searchResults.data.length} Understandings look similar to
            your question
          </div>
          <div className="mainstay-body-paragraph">
            If there's an Understanding with the same intent as your question,
            click to modify that instead of creating a new one.
          </div>
          {searchResults.data.length > 0 ? (
            <div className="d-flex flex-column">
              {searchResults.data.map((item, ix) => {
                return (
                  <SearchResult
                    {...item}
                    key={item.id}
                    firstInList={ix === 0}
                    onEdit={onEdit}
                  />
                )
              })}
            </div>
          ) : (
            <p>No Understandings found.</p>
          )}
        </div>
      ) : null}
    </>
  )
}
