export interface IIntroDialogCopyItem {
  WEB_HOOK: string
  UNKNOWN_STUDENT: string
  IM_SICK: string
}

interface IIntrodDialogCopy {
  title: IIntroDialogCopyItem
  description: IIntroDialogCopyItem
  recipients: IIntroDialogCopyItem
  trigger: IIntroDialogCopyItem
}

export const INTRO_DIALOGS_COPY: IIntrodDialogCopy = {
  title: {
    WEB_HOOK: 'Introduction to API-created Contacts',
    UNKNOWN_STUDENT: 'Introduction to Unknown SMS Contacts',
    IM_SICK: 'COVID-19 Information Script',
  },
  description: {
    WEB_HOOK:
      'When a contact is created via the API create-and-intro webhook, they enter the introduction script.',
    UNKNOWN_STUDENT:
      'When an unknown phone number messages your bot, they receive the SMS introduction script.',
    IM_SICK:
      'When a contact initiates the #imsick script, they receive the #imsick script',
  },
  recipients: {
    WEB_HOOK: 'API-created contacts',
    UNKNOWN_STUDENT: 'Unknown SMS contacts',
    IM_SICK: 'Contacts using the #imsick command',
  },
  trigger: {
    WEB_HOOK: 'Create-and-intro API webhook',
    UNKNOWN_STUDENT: 'Incoming SMS message from unknown number',
    IM_SICK: '#imsick command',
  },
}
