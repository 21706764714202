import * as api from 'api'
import { Dispatch } from 'store/store'
import { timeout } from 'util/timeout'
import { AxiosError } from 'axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as Raven from '@sentry/browser'
import {
  fetchCampaignDetails,
  generateCampaignReportProgress,
  generateCampaignReport,
  fetchCampaignImportDetails,
  fetchRecurringCampaignDetails,
  fetchAggRecurringCampaignDetails,
  fetchCampaignTriggerDetails,
  fetchIntroDialogVariantDetails,
} from 'store/campaign-details/actions'
import { HttpErrorKind } from 'store/webdata'
import { openDownloadURL } from 'util/links'
import { IPreferencesRequestPayload } from 'components/TrendsV2/CustomizedDownloadModal'
import { isRight } from 'fp-ts/lib/Either'
import { IWorkflowResponseChoice } from 'components/CampaignResultsCard/CampaignResultsCard'
import { ICampaign } from 'store/campaign-history/reducer'
import { ReportType } from 'store/campaign-details/reducer'

export const fetchCampaignDetailsAsync = (
  id: string,
  audience?: string,
  errorCb?: () => void
) => (dispatch: Dispatch) => {
  dispatch(fetchCampaignDetails.request())
  return api
    .getCampaignById(id, audience)
    .then(response => {
      dispatch(fetchCampaignDetails.success(response.data))
      if (response.data.campaign.recurring) {
        dispatch(fetchAggRecurringCampaignDetails.success(response.data))
      }
    })
    .catch((err: AxiosError<{ detail?: string }>) => {
      let errorKind = HttpErrorKind.other
      if (err.response && err.response.status === 404) {
        errorKind = HttpErrorKind.error404
      } else if (err.code === 'ECONNABORTED') {
        errorKind = HttpErrorKind.timeout
      }

      // If filtering by non-existent audience, this callback clears query params and redirects to unfiltered campaign detail page
      if (errorCb && err.response?.data?.detail === 'Invalid audience ID') {
        errorCb()
      }

      dispatch(fetchCampaignDetails.failure({ errorKind }))
    })
}

export const fetchRecurringCampaignDetailsAsync = (
  id: string,
  isAggregate: boolean
) => (dispatch: Dispatch) => {
  dispatch(fetchRecurringCampaignDetails.request())
  return api
    .getRecurringCampaignById(id, isAggregate)
    .then(response => {
      dispatch(fetchRecurringCampaignDetails.success(response.data))
      if (isAggregate) {
        dispatch(fetchAggRecurringCampaignDetails.success(response.data))
      }
    })
    .catch((err: AxiosError) => {
      let errorKind = HttpErrorKind.other
      if (err.response && err.response.status === 404) {
        errorKind = HttpErrorKind.error404
      } else if (err.code === 'ECONNABORTED') {
        errorKind = HttpErrorKind.timeout
      }
      dispatch(fetchRecurringCampaignDetails.failure({ errorKind }))
    })
}

export const fetchCampaignTriggerDetailsAsync = (
  id: string,
  audience?: string,
  errorCb?: () => void
) => (dispatch: Dispatch) => {
  dispatch(fetchCampaignTriggerDetails.request())
  return api
    .getCampaignTrigger({ id, audience })
    .then(res => {
      if (isRight(res)) {
        dispatch(fetchCampaignTriggerDetails.success(res.right))
      }
    })
    .catch((err: AxiosError<{ detail: string }>) => {
      let errorKind = HttpErrorKind.other
      if (err.response && err.response.status === 404) {
        errorKind = HttpErrorKind.error404
      } else if (err.code === 'ECONNABORTED') {
        errorKind = HttpErrorKind.timeout
      }

      // If filtering by non-existent audience, this callback clears query params and redirects to unfiltered campaign detail page
      if (errorCb && err.response?.data?.detail === 'Invalid audience ID') {
        errorCb()
      }

      dispatch(fetchCampaignTriggerDetails.failure({ errorKind }))
    })
}

export const fetchIntroDialogVariantDetailAsync = ({
  webBotIdToken,
  helloPageId,
  errorCb,
  audience,
  dialogId,
}: api.GetIntroCampaignVariantDetailsType & { errorCb: () => void }) => (
  dispatch: Dispatch
) => {
  dispatch(fetchIntroDialogVariantDetails.request())
  return api
    .getIntroCampaignVariantDetails({
      helloPageId,
      webBotIdToken,
      audience,
      dialogId,
    })
    .then(res => {
      if (isRight(res)) {
        dispatch(fetchIntroDialogVariantDetails.success(res.right))
      }
    })
    .catch((err: AxiosError<{ detail: string }>) => {
      let errorKind = HttpErrorKind.other
      if (err.response && err.response.status === 404) {
        errorKind = HttpErrorKind.error404
      } else if (err.code === 'ECONNABORTED') {
        errorKind = HttpErrorKind.timeout
      }

      // If filtering by non-existent audience, this callback clears query params and redirects to unfiltered campaign detail page
      if (errorCb && err.response?.data?.detail === 'Invalid audience ID') {
        errorCb()
      }

      dispatch(fetchIntroDialogVariantDetails.failure({ errorKind }))
    })
}

const campaignReportPollAsync = (
  campaignId: string,
  taskId: string,
  ms = 3000,
  onDownloadReportComplete?: () => void,
  isDataTriggered?: boolean
) => (dispatch: Dispatch) => {
  api
    .getCampaignStatusById(taskId, isDataTriggered)
    .then(async res => {
      if (res.data.status === 'SUCCESS') {
        dispatch(generateCampaignReport.success({ campaignId }))
        toast('Report successfully downloaded!', { type: 'success' })
        openDownloadURL({
          url: res.data.report_url,
        })
        onDownloadReportComplete?.()
      } else {
        dispatch(
          generateCampaignReportProgress({
            campaignId,
            progress: res.data.progress,
          })
        )
        await timeout(ms)
        campaignReportPollAsync(
          campaignId,
          taskId,
          ms,
          onDownloadReportComplete,
          isDataTriggered
        )(dispatch)
      }
    })
    .catch((e: AxiosError) => {
      handleReportError(e, campaignId)(dispatch)
    })
}

export interface IGenerateCampaignReportPayload {
  campaignId: ICampaign['id']
  preferences: IPreferencesRequestPayload | undefined
  reportFileName: string | undefined
  workflowResponses: IWorkflowResponseChoice[] | undefined
  onDownloadReportComplete?: () => void
  campaignReportType: ReportType
  isRecurring?: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  dialogId?: string
  isWebBot?: boolean
  audience?: string
}

export interface ICreateAudienceFromSurveyResponses {
  campaignId: ICampaign['id']
  audienceName: string
  contactLabelText: string
  workflowResponses: IWorkflowResponseChoice[]
  isRecurring?: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  dialogId?: string
  isWebBot?: boolean
  audience?: string
  region: string | null
}

export const generateCampaignReportAsync = ({
  campaignId,
  preferences,
  reportFileName,
  workflowResponses,
  campaignReportType,
  onDownloadReportComplete,
  isRecurring,
  isHelloPage,
  isWebBot,
  dialogId,
  isDataTriggered,
  isAggregate,
  audience,
}: IGenerateCampaignReportPayload) => (dispatch: Dispatch) => {
  dispatch(generateCampaignReport.request({ campaignId }))
  return api
    .generateCampaignReport({
      campaignId,
      preferences,
      reportFileName,
      workflowResponses,
      campaignReportType,
      isRecurring,
      isDataTriggered,
      isHelloPage,
      dialogId,
      isWebBot,
      isAggregate,
      audience,
    })
    .then(res => {
      const taskId = res.data.task_id
      campaignReportPollAsync(
        campaignId,
        taskId,
        3000,
        onDownloadReportComplete,
        isDataTriggered
      )(dispatch)
    })
    .catch((e: AxiosError) => {
      handleReportError(e, campaignId)(dispatch)
    })
}

const handleReportError = (e: AxiosError, campaignId: string) => (
  dispatch: Dispatch
) => {
  dispatch(generateCampaignReport.failure({ campaignId }))
  toast(
    'There was a problem downloading this report. Please try again later.',
    { type: 'error' }
  )
  Raven.captureException(e)
}

export const fetchCampaignImportDetailsAsync = (id: string) => (
  dispatch: Dispatch
) => {
  dispatch(fetchCampaignImportDetails.request())
  return api
    .getCampaignImportDetailsById(id)
    .then(response => {
      if (isRight(response)) {
        dispatch(fetchCampaignImportDetails.success(response.right))
      } else {
        dispatch(
          fetchCampaignImportDetails.failure({ errorKind: HttpErrorKind.other })
        )
      }
    })
    .catch((err: AxiosError) => {
      let errorKind = HttpErrorKind.other
      if (err.response && err.response.status === 404) {
        errorKind = HttpErrorKind.error404
      } else if (err.code === 'ECONNABORTED') {
        errorKind = HttpErrorKind.timeout
      }
      dispatch(fetchCampaignImportDetails.failure({ errorKind }))
    })
}
