import { useCallback, useState } from 'react'
import { CollapsibleSectionHeader } from 'components/CollapsibleSection/CollapsibleSection'
import { Button } from 'components/Button/Button'
import { Card } from 'reactstrap'
import { IWorkflowStepResult } from 'store/campaign-scripts/reducer'
import { WorkflowStepResults } from 'components/CampaignResultsCard/CampaignResultsCard'
import { generateDialogReport, getDialogStatusById } from 'api'
import { AxiosError } from 'axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { openDownloadURL } from 'util/links'
import { timeout } from 'util/timeout'
import { captureException } from '@sentry/browser'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionGuard from 'util/permissions/PermissionGuard'
import Tooltip from 'components/Tooltip/Tooltip'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'

enum DownloadResultsButtonState {
  Default = 'Download Results',
  Progress = 'Downloading Results...',
  Success = 'Downloaded Results',
}

export interface IScriptResponseResultsProps {
  steps: IWorkflowStepResult[]
  title: string
  info: string
  shown?: boolean
  dialogId?: string | null
  downloadResults?: boolean
}

export const ScriptResultsSection = (props: IScriptResponseResultsProps) => {
  const { steps, title, info, shown, dialogId, downloadResults } = props

  const [buttonState, setButtonState] = useState<DownloadResultsButtonState>(
    DownloadResultsButtonState.Default
  )

  const [showResults, setShowResults] = useState(shown)

  const dialogReportPollAsync = useCallback(
    (taskId: string, ms: number = 3000) => {
      getDialogStatusById(taskId)
        .then(async res => {
          if (res.data.status === 'SUCCESS') {
            toast('Report successfully downloaded!', { type: 'success' })
            setButtonState(DownloadResultsButtonState.Success)
            openDownloadURL({
              url: res.data.report_url,
            })
          } else {
            await timeout(ms)
            dialogReportPollAsync(taskId)
          }
        })
        .catch((e: AxiosError) => {
          toast(
            'There was a problem downloading this report. Please try again later.',
            { type: 'error' }
          )
          setButtonState(DownloadResultsButtonState.Default)
          captureException(e)
        })
    },
    []
  )

  const onDownloadResults = useCallback(() => {
    if (dialogId) {
      toast(
        'Downloading script response report. This may take a few minutes. Please do not close this page.',
        { type: 'info' }
      )
      setButtonState(DownloadResultsButtonState.Progress)
      generateDialogReport(dialogId, null, null, null, null, null)
        .then(res => {
          const taskId = res.data.task_id
          dialogReportPollAsync(taskId)
        })
        .catch((e: AxiosError) => {
          toast(
            'There was a problem downloading this report. Please try again later.',
            { type: 'error' }
          )
          setButtonState(DownloadResultsButtonState.Default)
          captureException(e)
        })
    }
  }, [dialogId, dialogReportPollAsync])

  return (
    <Card className="mt-2 px-2 py-2 bg-white">
      <div
        className="pointer d-flex justify-content-between align-items-center"
        onClick={() => setShowResults(value => !value)}>
        <div>
          <CollapsibleSectionHeader title={title} info={info} />{' '}
        </div>
        <div className="text-primary no-highlight mainstay-body-paragraph">
          {showResults ? 'Hide' : 'Show'} Results
        </div>
      </div>
      {showResults && (
        <div className="dialog-results">
          <div className="w-100">
            <WorkflowStepResults size="sm" steps={steps} />
          </div>
          <PermissionGuard permission={PERMISSIONS.REPORT.VIEW}>
            {downloadResults && (
              <Button
                onClick={onDownloadResults}
                color="link"
                disabled={buttonState !== DownloadResultsButtonState.Default}
                className="p-0">
                {buttonState}
              </Button>
            )}
          </PermissionGuard>
        </div>
      )}
    </Card>
  )
}

export interface IAnswerSaveButtonProps {
  tooltipText: string | undefined
  disabled: boolean
}

export interface IResponseCardFooterProps {
  onSave: () => void
  onCancel: () => void
  saveButtonProps: IAnswerSaveButtonProps
}

export const ResponseCardFooter = ({
  onSave,
  onCancel,
  saveButtonProps,
}: IResponseCardFooterProps) => {
  const saveButton = (
    <Button
      disabled={saveButtonProps.disabled}
      onClick={onSave}
      color="primary">
      Save
    </Button>
  )

  return (
    <div className="p-3 border-top d-flex">
      <PermissionGuard permission={PERMISSIONS.UNDERSTANDING.CREATE}>
        {saveButtonProps.tooltipText ? (
          <Tooltip content={saveButtonProps.tooltipText} placement="bottom">
            <div>{saveButton}</div>
          </Tooltip>
        ) : (
          saveButton
        )}
      </PermissionGuard>
      <Button onClick={onCancel} className="ml-1 fuzzy-question-cancel-button">
        Cancel
      </Button>
    </div>
  )
}
